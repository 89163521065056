import { FC } from "react";
import React from "react";
import { styled } from "@mui/material";
import weirdGredient from "../themes/weird-gredient";

interface AuthenticationProps {}

const StyledPage = styled("main")(({ theme }) => ({
  minHeight: "100vh",
  padding: "50px 10px",
  "::before": { ...weirdGredient, zIndex: -1 },
  h1: {
    color: theme.palette.primary.main,
    textAlign: "center",
  },
  ".description": {
    textAlign: "center",
    fontWeight: 500,
    marginTop: 10,
  },
  ".content": {
    maxWidth: 480,
    margin: "auto",
    marginTop: 20,
  },
  ".bottom_section": {
    alignItems: "center",
  },
  ".link": {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  ".user_type_label": {
    textTransform: "capitalize",
  },
}));

const AuthenticationLayout: FC<AuthenticationProps> = ({ children }) => {
  return <StyledPage>{children}</StyledPage>;
};

export default AuthenticationLayout;
