import { FC } from "react";
import { Language } from "../../constants/languages";
import EduLevel from "./sections/edu-levels";
import Gender from "./sections/gender";
import Industry from "./sections/industry";
import JobType from "./sections/job_type";
import Location from "./sections/location";
import PostedDate from "./sections/posted_date";
import SalaryRange from "./sections/salary_range";

export const data: Filter[] = [
  { label: { en: "Industry" }, id: "industry", content: Industry },
  { label: { en: "Job Type" }, id: "job_type", content: JobType },
  { label: { en: "hiring location" }, id: "location", content: Location },
  { label: { en: "Gender" }, id: "gender", content: Gender },
  { label: { en: "Education Level" }, id: "edu", content: EduLevel },
  { label: { en: "Salary" }, id: "salary", content: SalaryRange },
  { label: { en: "Posted Date" }, id: "posted_date", content: PostedDate },
];

export type Filter = {
  label: Language;
  id: string;
  content?: Content<any>;
};

export type Content<T = unknown> = FC<{
  id: string;
  update: (value: T) => void;
  value?: T;
}>;
