import { KeyboardArrowRight } from "@mui/icons-material";
import { Breadcrumbs, styled, Typography } from "@mui/material";
import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { Language } from "../../constants/languages";
import usePath from "../../hooks/path.hook";
import { useLanguage } from "../../store/providers/lang.provider";

interface HeaderProps {
  title: Language;
}
const StyledHeader = styled("header")(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(5, 0),
  marginBottom: theme.spacing(5),
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  ".active": {
    textDecoration: "underline",
  },
  ".link, svg": {
    fontWeight: "700",
    color: theme.palette.primary.contrastText,
  },
}));

const Header: FC<HeaderProps> = ({ title }) => {
  const { code } = useLanguage();
  const path = usePath();
  return (
    <StyledHeader>
      <Typography variant="h1">{title[code]}</Typography>
      <Breadcrumbs
        separator={<KeyboardArrowRight />}
        style={{
          textTransform: "capitalize",
        }}
      >
        {path.map((pathPiece, i) => (
          <NavLink
            className={(i === path.length - 1 ? "active" : "") + " link"}
            to={pathPiece.link}
            key={pathPiece.link}
          >
            {pathPiece.piece}
          </NavLink>
        ))}
      </Breadcrumbs>
    </StyledHeader>
  );
};

export default Header;
