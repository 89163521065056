import { Stack, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SubmitButton from "../../components/submit-button";
import { REGISTERED_NUM } from "../../constants/keys";
import { MOBILE_NUM_REGEX, PASSWORD_REGEX } from "../../constants/regex";
import { DATA_USER_TYPES } from "../../constants/user-types";
import * as authService from "../../services/auth-service";
import { useAuthentication } from "../../store/providers/auth.provider";
import { useLanguage } from "../../store/providers/lang.provider";
import { useSnackbar } from "../../store/providers/snackbar.provider";
import { Credentials, ErrorHelps, InputField } from "../../utils/auth-types";
import { Errors } from "../../utils/errors";

const LoginPage: FC = () => {
  const [credentials, setCredentials] = useState<Credentials>({
    type: DATA_USER_TYPES[0].id,
  });
  const { update } = useAuthentication();
  const { code } = useLanguage();
  const { addSnack } = useSnackbar();
  const navigate = useNavigate();

  const updateCredentials =
    (name: string, validate: InputField["validator"]) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setCredentials((prev) => ({
        ...prev,
        [name]: validate?.(value) ? value : Errors.INPUT_ERROR,
      }));
    };

  const submit = async () => {
    try {
      const response = await authService.login(credentials);

      if (!response.success) return;

      if (!response.verify) {
        addSnack?.({
          severity: "warning",
          message: `Please verify your account before logging in`,
        });
        sessionStorage.setItem(REGISTERED_NUM, response.number);
        return navigate(`/verify`);
      }
      update?.(response);
      if (!response.completed) {
        addSnack?.({
          severity: "info",
          message: `Please take a moment to complete your profile`,
        });

        return navigate(
          response.role === "candidate"
            ? "/profile/candidate/edit"
            : "/profile/company/edit"
        );
      }

      addSnack?.({
        severity: "success",
        message: `You've successfully logged in`,
      });
      navigate("/");
    } catch (error: any) {
      addSnack?.({
        severity: "error",
        message: error.message,
      });
    }
  };
  return (
    <>
      <Typography variant="h1">Login</Typography>
      <Typography className="description">
        Welcome back. Sign in to your account
      </Typography>

      <Stack spacing={4} className="content">
        {inputs.map((input) => {
          const hasError = credentials?.[input.name] === Errors.INPUT_ERROR;
          const Input = input.CustomComponent ?? TextField;
          return (
            <Input
              {...input.props}
              error={hasError}
              name={input.name}
              key={input.name}
              helperText={hasError ? errorHelps[input.name]?.[code] : ""}
              onChange={updateCredentials(input.name, input.validator)}
            />
          );
        })}
        <Link to="/forgot-password">
          <Typography className="link">Forgot Your Password?</Typography>
        </Link>
        <Stack className="bottom_section" spacing={2}>
          <SubmitButton onClick={submit}>Login</SubmitButton>

          <Stack direction="row" spacing={1}>
            <Typography color="secondary">Not Registered Yet?</Typography>
            <Link to="/register">
              <Typography className="link">Register</Typography>
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export const inputs: InputField[] = [
  /*
  {
    name: "type",
    validator: (type: UserType) => !!userTypes.find((t) => t.id === type),
    CustomComponent: ({
      error,
      onChange,
      name,
      helperText,
    }: CustomComponentProps) => (
      <FormControl error={error} variant="standard">
        <FormLabel required id="user-type">
          Employer Type
        </FormLabel>
        <RadioGroup
          aria-labelledby="user-type"
          defaultValue={userTypes[0]?.id}
          name={name}
          onChange={onChange}
        >
          <Stack direction="row">
            {userTypes.map((type) => (
              <FormControlLabel
                key={type.id}
                value={type.id}
                label={type.displayText}
                control={<Radio />}
                className="user_type_label"
              />
            ))}
          </Stack>
        </RadioGroup>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    ),
  },
  */
  {
    name: "number",
    validator: (number) => MOBILE_NUM_REGEX.test(number),
    props: {
      placeholder: "Enter your mobile number",
      label: "Mobile Number",
      required: true,
    },
  },
  {
    name: "password",
    validator: (password) => PASSWORD_REGEX.test(password),
    props: {
      placeholder: "Enter your password",
      type: "password",
      required: true,
      label: "Password",
    },
  },
];

const errorHelps: ErrorHelps = {
  type: {
    en: "Invalid Type",
    si: "",
  },
  number: {
    en: "Entered Number is Invalid",
    si: "",
  },
  password: {
    en: "Password need to be at least 8 characters long with a Number and a special character",
    si: "",
  },
};

export default LoginPage;
