export default {
  content: "''",
  position: "absolute",
  width: "100%",
  height: "50vh",
  background: `linear-gradient(180deg, 
    #2A8989 0%, 
      rgba(42, 137, 137, 0.85625) 0.01%, 
      rgba(42, 137, 137, 0.371971) 78.96%, 
      rgba(42, 137, 137, 0) 100%)`,
  left: 0,
  top: 0,
};
