import {
  Button,
  Container,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import React, { FC, useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import { Link } from "react-router-dom";
import Instructions from "../../components/home/instructions";
import Title from "../../components/home/section-title";
import { CandidateCardProps, JobCardProps } from "../../components/job-cards";
import MiniCard from "../../components/job-cards/vertical";
import useBackend from "../../hooks/backend";
import { useSnackbar } from "../../store/providers/snackbar.provider";
import Loader from "../loader/Loader";

import "react-alice-carousel/lib/alice-carousel.css";
import SubmitButton from "../../components/submit-button";
import ContactSpeedDial from "../../components/speed-dial";

interface HomePageProps {}

const StyledPage = styled("main")(({ theme }) => ({
  section: {
    padding: theme.spacing(5, 0),
  },
  ".images": {
    display: "flex",
    justifyContent: "space-around",
  },
  ".images .cover": {
    width: "100%",
    borderRadius: 10,
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 240,
    },
  },
  ".instructions": {
    padding: theme.spacing(5, 2),
    background: grey["100"],
  },
  ".user-statements": {
    background: grey["100"],
  },
  ".questions": {
    padding: theme.spacing(5, 2),
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "p a": {
      color: theme.palette.primary.contrastText,
      fontWeight: "500",
    },
  },
  ".user-statements, .questions": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  ".contact-button": {
    marginTop: theme.spacing(2),
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
  },
  ".contact-button:hover": {
    background: grey["100"],
  },
  ":where(img)": {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  ".cover img": {
    objectFit: "cover",
  },
}));

const HomePage: FC<HomePageProps> = () => {
  const { data, loading, error } = useBackend<Response, Error>(
    "/advertisement/home"
  );
  const { addSnack } = useSnackbar();

  useEffect(() => {
    if (error)
      addSnack?.({
        severity: "error",
        message: error?.message ?? "Unknown error",
      });
  }, [error]);

  return (
    <StyledPage>
      <Container>
        <Loader loading={loading} />
        <section>
          <Stack
            direction={{
              md: "row",
              xs: "column",
            }}
            alignItems="center"
            spacing={3}
          >
            <Box flex={1}>
              <Typography color="primary" variant="h1">
                Find Your Dream
              </Typography>
              <Typography color="secondary" marginY={2}>
                <p>
                  At JobHere, we provide UNIQUE service to find the right ONE!
                </p>
                <p>
                  We make it easy to find the BEST fit for your DREAM
                  opportunity.
                </p>
                <p>
                  In particular, we make it easy for you to find the correct fit
                  for the correct opportunity regardless of whether you are a
                  jobhunter or headhunter. Let&apos;s explore our potential in
                  seconds! Let&apos;s do it…!
                </p>
                <p>
                  If you are a RECRUITER looking for perfect employees, please
                  click below on “HIRE PEOPLE.”
                </p>
                <p>
                  If you are a CANDIDATE seeking an ideal career opportunity
                  that matches your profile, please click below on “FIND JOB.”
                </p>
              </Typography>

              <Stack direction="row" spacing={2}>
                <Button
                  component={Link}
                  to={"/hire-people"}
                  size="large"
                  variant="contained"
                >
                  Hire People
                </Button>
                <Button
                  component={Link}
                  to={"/find-job"}
                  size="large"
                  variant="outlined"
                >
                  Find Job
                </Button>
              </Stack>
            </Box>
            <Stack
              direction={{ md: "row", xs: "column" }}
              spacing={1}
              className="images"
              flex={1}
            >
              <div className="cover">
                <img src="/assets/girl.jpg" alt="" height={380} />
              </div>
              <Box display={{ md: "block", xs: "none" }}>
                <div className="cover">
                  <img src="/assets/girl2.jpg" alt="" height={380} />
                </div>
              </Box>
            </Stack>
          </Stack>
        </section>
        <section aria-labelledby="home_page_jobs">
          <Title id="home_page_jobs">
            What kind of
            <strong aria-label="job">Job</strong>
            are you looking for
          </Title>

          <AliceCarousel
            mouseTracking
            responsive={responsive}
            controlsStrategy="alternate"
            disableButtonsControls
            items={data?.result.jobs?.map((request) => (
              <Box marginX={1} key={request.id}>
                <MiniCard type="employer" card={request} />
              </Box>
            ))}
          />
          <Link to="/find-job">
            <SubmitButton>See More</SubmitButton>
          </Link>
        </section>
      </Container>
      <section aria-labelledby="home_page_jobs_works">
        <Paper elevation={0} className="instructions">
          <Title id="home_page_jobs_works">
            <strong>How JOBHERE Works For You</strong>
          </Title>
          <Instructions />
        </Paper>
      </section>
      <section aria-labelledby="home_page_candidates">
        <Title id="home_page_candidates">
          What kind of <strong aria-label="candidate">Candidate</strong>
          are you looking for
        </Title>
        <Container>
          <AliceCarousel
            mouseTracking
            responsive={responsive}
            controlsStrategy="alternate"
            disableButtonsControls
            items={data?.result.jobRequests?.map((request) => (
              <Box marginX={1} key={request.id}>
                <MiniCard type="candidate" card={request} />
              </Box>
            ))}
          />
          <Link to="/hire-people">
            <SubmitButton>See More</SubmitButton>
          </Link>
        </Container>
      </section>

      <section
        className="user-statements"
        aria-labelledby="home_page_testomonials"
      >
        <Title id="home_page_testomonials">
          <strong>What Our Users Say</strong>
        </Title>
        <Typography maxWidth={800} marginTop={3}>
          “I have been struggling to put my personal information into a modern,
          attractive, and stylish CV template. I have tried a few paid options
          available online. Yet, my CV looked old-fashioned and it displayed my
          personal data in an inconsistent manner. I was not at all satisfied
          with the appearance of my CV and how I marketed myself in such a
          traditional template. I was helpless, but I could not do anything
          about it. Luckily, I got to know about the CV generator function
          available at JobHere. I must say they have a wide range of templates
          suitable for any profession. Finally, thanks to JobHere, I was able to
          find the perfect template for my CV.”
        </Typography>

        <Typography variant="body1" fontWeight="700" marginTop={2}>
          Mr. Udaya Weerasinghe
        </Typography>
        <Typography variant="body2" fontWeight="700">
          Executive Officer - XYZ (Pvt.) Ltd.
        </Typography>
      </section>

      <Paper
        elevation={0}
        className="questions"
        aria-labelledby="home_page_questions"
      >
        <Title id="home_page_questions">Have Any Questions?</Title>
        <Typography maxWidth={800} marginTop={3}>
          If you have any questions, comments or requests, please do not
          hesitate to contact us via{" "}
          <a href="mailto:jobhear@gmail.com">jobhear@gmail.com</a>. We would
          love to hear from you.
        </Typography>

        <Button
          component={Link}
          to="/contact-us"
          variant="contained"
          className="contact-button"
          size="large"
        >
          Contact Us
        </Button>
      </Paper>
      <ContactSpeedDial />
    </StyledPage>
  );
};

const responsive = {
  0: { items: 1 },
  480: { items: 2 },
  900: { items: 3 },
};

export interface Response {
  success: boolean;
  result: Result;
}

export interface Result {
  jobRequests: CandidateCardProps[];
  jobs: JobCardProps[];
}
export default HomePage;
