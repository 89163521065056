import { Close, ExpandMore, Refresh, Tune } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Stack,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { FC, useState } from "react";
import { KeyedMutator } from "swr";
import { Result } from ".";
import * as searchService from "../../services/search-service";
import { useLanguage } from "../../store/providers/lang.provider";
import { useSnackbar } from "../../store/providers/snackbar.provider";
import { Response } from "../../utils/utils.types";
import SubmitButton from "../submit-button";
import { data } from "./filter.data";
import useBackend from "../../hooks/backend";
import { useParams, useSearchParams } from "react-router-dom";

const drawerWidth = 350;

const Aside = styled("aside")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: "100%",
    maxWidth: drawerWidth,
  },
  ".filter": {
    "& .MuiDrawer-paper": {
      maxWidth: drawerWidth,
      boxSizing: "border-box",
      [theme.breakpoints.up("md")]: {
        position: "relative",
      },
      
    },
  },
}));

export type FilterType = {
  [key: string]: any;
};
interface FilterProps {
  type: any;
  open: boolean;
  onClose: () => void;
  mutateResult: KeyedMutator<Response<Result>>;
  onClear?: () => void;
}
const Filter: FC<FilterProps> = ({ type, open, onClose, mutateResult,onClear }) => {
  const { code } = useLanguage();
  const { query } = useParams();
  const [params] = useSearchParams();
  const page = params.get("page") ?? "1";
  const [url1,setUrl1] = useState("");
  const { addError } = useSnackbar();
  const [filter, setFilter] = useState<FilterType>({});
  const [currentOpen, setCurrentOpen] = useState(0);
  const {} = useBackend<Response<Result>, Error>(
    [url1, type, query, page],
    { params: { search: query, page, type } }
  );
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const updateFilter =
    <T,>(key: string) =>
    (value: T) => {
      setFilter((prev) => ({ ...prev, [key]: value }));
    };

  const clearFilter = () => {
    setFilter({});
    setUrl1("/advertisement");
  };
  const onAccordionChange = (id: number) => (_: any, expanded: boolean) => {
    setCurrentOpen(expanded ? id : -1);
  };

  const search = async () => {
    try {
      const formatted = {
        ...filter,
        job_type: getSelectedInList(filter.job_type ?? {}),
        gender: getSelectedInList(filter.gender ?? {}, true),
        industry: Object.entries(filter.industry ?? {}).map(([id, value]) => ({
          id,
          job_types: getSelectedInList(value as object),
        })),
      };

      const result = await searchService.filterJob(type, formatted);
      mutateResult(result, { revalidate: false });
      onClose?.();
    } catch (error: any) {
      addError?.(error.message);
    }
  };

  return (
    <Aside aria-label="Search Filter">
      <Drawer
        open={!isTablet || open}
        variant={isTablet ? "temporary" : "permanent"}
        anchor="left"
        className="filter"
        onClose={onClose}
        PaperProps={{
          sx: (theme) => ({
            [theme.breakpoints.down("md")]: { width: "100%" },
            zIndex:100,
          }),
        }}
      >
        <Toolbar disableGutters>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            padding={1}
          >
            <Tune color="primary" />
            <Typography color="primary" fontWeight="700">
              Filters
            </Typography>
            <Button
              startIcon={<Refresh />}
              variant="outlined"
              onClick={clearFilter}
            >
              Clear All
            </Button>
            <SubmitButton
              onClick={search}
              sx={(theme) => ({
                alignSelf: "center",
                width: "fit-content",
                [theme.breakpoints.down("md")]: {
                  display: "none",
                },
              })}
            >
              Apply
            </SubmitButton>
            <Box display={{ xs: "flex", md: "none" }}>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
          </Stack>
        </Toolbar>
        <Divider />

        <Stack paddingBottom={4} divider={<Divider />}>
          {data?.map((section, i) => {
            const Content = section.content ?? (() => null);
            return (
              <Accordion
                key={section.id}
                TransitionProps={{ unmountOnExit: true }}
                elevation={0}
                expanded={currentOpen === i}
                onChange={onAccordionChange(i)}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography fontWeight="700">
                    {String(section.label[code])}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Content
                    update={updateFilter(section.id)}
                    value={filter[section.id]}
                    id={section.id}
                  />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Stack>

        <SubmitButton
          onClick={search}
          sx={(theme) => ({
            alignSelf: "center",
            [theme.breakpoints.down("md")]: {
              width: "100%",
              position: "sticky",
              bottom: 0,
              mb: theme.spacing(1),
              "&::after": {
                position: "absolute",
                content: "''",
                width: "95vw",
                height: "100%",
                zIndex: -1,
              },
            },
          })}
        >
          Apply filters
        </SubmitButton>
      </Drawer>
    </Aside>
  );
};

const getSelectedInList = (obj: object, asInt = false) => {
  return Object.entries(obj)
    .filter(([, value]) => value)
    .map(([id]) => (asInt ? parseInt(id) : id));
};
export default Filter;
