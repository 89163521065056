import { Search, Tune } from "@mui/icons-material";
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  ChangeEventHandler,
  FC,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserType } from "../../constants/input-data";
import useBackend from "../../hooks/backend";
import { useSnackbar } from "../../store/providers/snackbar.provider";
import { Response } from "../../utils/utils.types";
import Loader from "../../views/loader/Loader";
import { JobCardProps } from "../job-cards";
import JobCard from "../job-cards/horizontal";
import Filter from "./filter";

const StyledMain = styled("main")(({ theme }) => {
  const borderRadius = 10;
  return {
    padding: theme.spacing(5, 0),
    ".searchbar": {
      background: theme.palette.background.default,
      borderRadius,
    },
    ".body": {
      margin: theme.spacing(3, 2),
      background: "#ffffff",
      borderRadius,
    },
    ".result": {
      padding: theme.spacing(2),
    },
    ".paginator": {
      paddingTop: theme.spacing(4),
      width: "fit-content",
      margin: "auto",
    },
  };
});

type Props = {
  type: UserType;
  navigateLink: (query: string) => string;
  query?: string;
  url: string;
};
const FindUsers: FC<Props> = ({ type, query, navigateLink, url }) => {
  const [showFilter, setShowFilter] = useState(false);
  const [newQuery, setNewQuery] = useState(query ?? "");
  const [params] = useSearchParams();
  const page = params.get("page") ?? "1";

  const { data, loading, error, mutate } = useBackend<Response<Result>, Error>(
    [url, type, query, page],
    { params: { search: query, page, type } }
  );

  const submitButton = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();

  const { addSnack } = useSnackbar();

  useEffect(() => {
    if (error)
      addSnack?.({
        severity: "error",
        message: error?.message ?? "Unknown error",
      });
  }, [error]);

  const toggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const redirect = () => {
    navigate(navigateLink(newQuery));
  };
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setNewQuery(e.target.value);
  };

  const redirectToPage = (_: any, page: number) => {
    navigate(navigateLink(newQuery) + `?page=${page}`);
  };

  return (
    <StyledMain>
      <Container>
        <Loader loading={loading} />
        <Stack direction="row">
          <Box
            width="100%"
            color="primary"
            padding={{ xs: 1, md: 2 }}
            className="searchbar"
          >
            <TextField
              fullWidth
              id="search-query"
              aria-label="Type search query"
              placeholder="Type to search ..."
              variant="outlined"
              onChange={onChange}
              onKeyPress={(e) =>
                e.key === "Enter" ? submitButton.current?.click() : undefined
              }
              style={{ background: "#F8F8F8" }}
              value={newQuery}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography paddingX={1}>Search Ad</Typography>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton ref={submitButton} onClick={redirect}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            bgcolor="#f8f8f8"
            borderRadius={1.5}
            width={100}
            display={{ xs: "flex", md: "none" }}
            justifyContent="center"
            alignItems="center"
          >
            <IconButton size="large" onClick={toggleFilter}>
              <Tune fontSize="large" />
            </IconButton>
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={{ xs: 1, md: 3 }}
          // divider={!isMobile && <Divider orientation="vertical" flexItem />}
        >
          <Filter
            type={type}
            open={showFilter}
            onClose={toggleFilter}
            mutateResult={mutate}
          />

          <Paper
            style={{ marginLeft: 0 }}
            elevation={0}
            sx={{ flex: { xs: 1, sm: 3 }, p: { sm: 2 }, py: 2 }}
          >
            <Stack spacing={2}>
              {!data?.result.data.length && !loading && (
                <Typography
                  variant="h2"
                  paragraph
                  textAlign="center"
                  color="secondary"
                >
                  No Results found
                </Typography>
              )}
              {data?.result.data.map((card) => (
                <JobCard card={card} key={type + card.id} />
              ))}
            </Stack>

            <Pagination
              count={data?.result.pages ?? 1}
              className="paginator"
              page={parseInt(page)}
              onChange={redirectToPage}
            />
          </Paper>
        </Stack>
      </Container>
    </StyledMain>
  );
};

export interface Result {
  pages: number;
  data: JobCardProps[];
}

export default FindUsers;
