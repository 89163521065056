import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  styled,
} from "@mui/material";
import React, { FC, useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import { useNavigate } from "react-router-dom";
import { Main } from "../../../components/styled-common/main";
import useBackend from "../../../hooks/backend";
import { getCVTemplate } from "../../../services/profile-service";
import { useSnackbar } from "../../../store/providers/snackbar.provider";
import { Response } from "../../../utils/utils.types";
import Loader from "../../loader/Loader";

const StyledBox = styled(Box)(() => ({
  position: "relative",
  button: {
    display: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  "&:hover .button": {
    display: "block",
  },
  img: {
    border: "1px solid whitesmoke",
  },
}));

const CVOnboarding: FC = () => {
  const navigate = useNavigate();
  const { addError } = useSnackbar();

  const { data, loading, error } = useBackend<Response<Template[]>, Error>(
    "/system/cv"
  );

  useEffect(() => {
    if (error) addError?.(error?.message ?? "Unknown error");
  }, [error]);

  const images = data?.result;

  const onChooseTemplate = (id: number) => () => {
    if (!id) return;
    navigate(`/cv-gen/${id}`);
  };
  return (
    <Main>
      <Container>
        <Stepper style={{ marginBottom: 24 }} alternativeLabel>
          {steps.map((step, i) => (
            <Step key={i}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Loader loading={loading} />
     
        <Grid container spacing={2}>
          {console.log("images",images)}
          {images?.map((img) => (
    
             <StyledBox minHeight={500} key={img.id} marginX={1}>
              <Button
                className="button"
                variant="contained"
                onClick={onChooseTemplate(img.id)}
              >
                Select this Template
              </Button>
              <img
                src={getCVTemplate(img.path)}
                alt={img.label ?? "CV template"}
                draggable={false}
                height={500}
                width={350}
              />
              
            </StyledBox>
           
          ))}
        </Grid>
      
      </Container>
    </Main>
  );
};

const responsive = {
  0: { items: 1 },
  480: { items: 2 },
  900: { items: 3 },
};

const steps = [
  { label: "Select Template" },
  { label: "Add Information" },
  { label: "Download" },
];

export interface Template {
  id: number;
  label: string;
  path: string;
}
export default CVOnboarding;
