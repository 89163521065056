import { Paid, Receipt, Work } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import InfoCard from "../../../components/info-card";
import useBackend from "../../../hooks/backend";
import { getCVTemplate } from "../../../services/profile-service";
import { SpecialPerson } from "../../../utils/icons";
import { Response } from "../../../utils/utils.types";
import { Template } from "./onboarding";

const StyledMain = styled("main")(() => ({
  ".blob": {
    zIndex: -1,
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    top: 0,
  },
}));
const ASPECT = 247 / 315;
const HEIGHT = 450;

const CVGenerate: FC = () => {
  const { data } = useBackend<Response<Template[]>, Error>("/system/cv");
  const images = data?.result;

  return (
    <StyledMain>
      <img src="/assets/cv-blob.svg" className="blob" alt="" />

      <Container>
        <section aria-labelledby="heading">
          <Stack
            padding={2}
            spacing={3}
            marginX={5}
            direction="row"
            alignItems="center"
            minHeight="90vh"
            justifyContent="space-between"
          >
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                },
              })}
            >
              <Typography variant="h1" id="heading" color="primary">
                Apply Your Dream Job
              </Typography>
              <Typography marginBottom={3} maxWidth="50ch">
                Use our resume templates, professionally reviewed and tested.
                Editor easy to use and done in minutes Try it for free!
              </Typography>

              <Button component={Link} to="onboarding" variant="contained">
                Create CV
              </Button>
            </Box>
            <Box display={{ xs: "none", md: "block" }}>
              <img
                src="/assets/cv.png"
                alt="Automatically Created sample CV"
                height={HEIGHT}
                width={HEIGHT * ASPECT}
              />
            </Box>
          </Stack>
        </section>

        <Stack direction={{ md: "row", xs: "column" }} spacing={3} paddingY={5}>
          {cards.map((card, i) => (
            <InfoCard
              icon={card.icon}
              key={i}
              title={
                <span>
                  Quick and Easy <br />
                  {card.title}
                </span>
              }
              content={card.content}
            />
          ))}
        </Stack>

        <Stack
          direction={{ md: "row", xs: "column-reverse" }}
          justifyContent="space-between"
          paddingY={5}
        >
          <Box flex={1}>
            <img
              src={getCVTemplate(images?.[0].path)}
              height={HEIGHT * 1.5}
              width={HEIGHT * 1.5 * ASPECT}
              alt="Automatically Created full sample CV"
            />
          </Box>
          <Box flex={1}>
            <Typography variant="h2" color="primary">
              Create Your CV Now
            </Typography>
            <Typography paragraph>
              <p>
                We offer a range of latest, modern and stylish CV templates with
                a minimal cost or ZERO COST. Our prime aim is to provide the
                best available services for our users with high quality. We have
                observed the struggle that most candidates go over when it comes
                to putting information into a 1- 2 pages document called CV or
                RESUME. We believe that CV is the first contact point in the
                pathway of career hunting. We are aware that modern, stylish,
                and updated CV, it can do a LOT MORE to succeed in obtaining a
                career in addition to your educational and professional
                qualifications.
              </p>
              <p>
                At present, we offer this service 100% FREE of charge for
                anyone, but in the future, we are planning to offer a variety of
                PAID CV templates as well in order to better score yourself and
                attract recruiters. Keep in touch with JobHere to discover more
                TEMPLATES!
              </p>
            </Typography>
          </Box>
        </Stack>

        <Stack
          direction={{ md: "row", xs: "column" }}
          justifyContent="space-evenly"
          paddingY={5}
          spacing={3}
        >
          <Box flex={1}>
            <Typography variant="h2" color="primary">
              Discover Our Templates
            </Typography>
            <Stack spacing={2}>
              {templateCards.map((card, i) => (
                <InfoCard
                  icon={card.icon}
                  key={i}
                  title={`Quick and Easy: ${card.title}`}
                  content={card.content}
                />
              ))}

              <Link to="onboarding">
                <Button
                  style={{ width: "fit-content", alignSelf: "center" }}
                  variant="contained"
                >
                  View All Templates
                </Button>
              </Link>
            </Stack>
          </Box>
          <Stack flex={1} flexWrap="wrap" direction="row" gap={4}>
            {images?.slice(1, 5).map((img, i) => (
              <Box key={i}>
                <img
                  src={getCVTemplate(img.path)}
                  alt={img.label ?? "CV template"}
                  draggable={false}
                  height={300}
                  width={150}
                />
              </Box>
            ))}
          </Stack>
        </Stack>
      </Container>
    </StyledMain>
  );
};

const cards = [
  {
    title: "Step 1",
    content:
      "Choose the sample template of your interest which represent you well among the other candidates by clicking “View All Templates.”",
    icon: Work,
  },
  {
    title: "Step 2",
    content:
      "Feed your personal, educational, professional, technical, extracurricular, and other relevant information to the selected format.",
    icon: Work,
  },
  {
    title: "Step 3",
    content:
      "Double check your details and click the “Generate CV” button to automatically create a full sample CV.",
    icon: Work,
  },
];
const templateCards = [
  {
    title: "Unique Templates",
    content:
      "Our templates are made to match your choice. Our templates are UNIQUE and UNCOMMON",
    icon: Receipt,
  },
  {
    title: "Suitable for Every Profession",
    content:
      "Our templates suit any kind of profession, regardless of whether it is full-time or parttime/academic or non-academic/executive or clerical.",
    icon: SpecialPerson,
  },
  {
    title: "Template Rates",
    content:
      "We offer both paid and unpaid templates for you to shine among many others.",
    icon: Paid,
  },
];

export default CVGenerate;
