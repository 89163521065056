import { FC } from "react";
import { Navigate } from "react-router-dom";
import { useAuthentication } from "../store/providers/auth.provider";
import React from "react";

const ProtectedLayout: FC = ({ children }) => {
  const { success } = useAuthentication();
  if (success) return <>{children}</>;
  return <Navigate to={"/login"} />;
};

export default ProtectedLayout;
