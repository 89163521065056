import { FC } from "react";
import Footer from "./common/footer";
import NavBar from "./common/nav-bar";
import React from "react";

interface LayoutProps {}

const MainLayout: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <NavBar />
      {children}
      <Footer />
    </>
  );
};

export default MainLayout;
