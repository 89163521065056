import { ExpandMore, Work } from "@mui/icons-material";
import {
  AppBar,
  Badge,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Theme,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import React, {
  FC,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Notification } from "../../../components/notifications/types";
import { SocketEvents } from "../../../constants/event-names";
import { useSocketEvent } from "../../../hooks/socket";
import { useToggle } from "../../../hooks/toggle.hook";
import * as profileService from "../../../services/profile-service";
import { useAuthentication } from "../../../store/providers/auth.provider";
import { useSnackbar } from "../../../store/providers/snackbar.provider";
import InnerLinks from "./inner-links";
import getLinks from "./nav-links";

const MAX_NAVBAR_LINKS_VISIBLE = 4;

const MobileMenu = React.lazy(() => import("./mobile-menu"));

interface NavBarProps {}

const NavBar: FC<NavBarProps> = () => {
  const isTablet = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("md")
  );

  const { addSnack } = useSnackbar();
  const navigate = useNavigate();
  const { token, role, lastlogin, adposted,completed } = useAuthentication();
  const [newDots, setNewDots] = useState<{ [key: string]: number }>({});
  const [openOtherLinks, toggleOpenOtherLinks] = useToggle();
  const [addPostDisabled, setAddPostDisabled] = useState(false);

 
  useEffect(() => {
    if (token) getBubbles();
  }, [token]);

  const otherLinksButton = useRef<HTMLButtonElement>(null);

  const [fullLinks, otherLinks] = useMemo(() => {
    const allLinks = getLinks(role);

    const full = allLinks.slice(0, MAX_NAVBAR_LINKS_VISIBLE);
    const others = allLinks.slice(MAX_NAVBAR_LINKS_VISIBLE);

    return [full, others];
  }, [role]);

  useEffect(() => {
    if (role === undefined) {
      postAdLink = "#";
    } else {
      postAdLink =
        role === "employer" ? "/post-ad/post" : "/profile/candidate/edit";
    }
  }, [role]);

  let postAdLink =
    role === "employer" ? "/post-ad/post" : "/profile/candidate/edit";

  const updateDots = (id: string, count: (prev: number) => number) => {
    setNewDots((prev) => ({ ...prev, [id]: count(prev[id] ?? 0) }));
  };
  const removeDot = (id?: string) => () => {
    if (!id) return;
    updateDots(id, () => 0);
  };
  const [canPost,setCanPost] = useState(false);


  useEffect(() =>{
    isCompleted();
  },[])

  const isCompleted = async () =>{
    const { result } = await profileService.status();
    setCanPost(result.completed)

  }

  const getBubbles = async () => {
    try {
      const { result } = await profileService.getInitialNotifications(
        lastlogin
      );
      updateDots("messages", (prev) => prev + result.chats);
      updateDots("notifications", (prev) => prev + result.notifications);
    } catch (error) {
      //
    }
  };

  useSocketEvent(SocketEvents.NEW_MESSAGE_CAME, () => {
    updateDots("masseges", (prev) => prev + 1);
  });
  useSocketEvent(SocketEvents.NOTIFICATION, (notification: Notification) => {
    updateDots("notifications", (prev) => prev + 1);
    addSnack?.({
      severity: "info",
      message: notification.title,
      onClick: () => navigate("/notifications"),
    });
  });

  return (
    <AppBar
      color="default"
      sx={(theme) => ({ [theme.breakpoints.up("md")]: { zIndex: 9999 } })}
      position="sticky"
      elevation={0}
      className="not-printable"
    >
      <Toolbar
        variant="regular"
        style={{
          justifyContent: "space-between",
          padding: "10px 25px",
        }}
      >
        <Link to={"/"}>
          <Box width={{ xs: 100, md: 200 }}>
            <img
              height={50}
              width={260}
              src="/assets/new.png"
              alt="Jobhere logo"
            />
          </Box>
        </Link>
        <nav aria-label="Main">
          {isTablet ? (
            <>
              {!adposted && canPost && (
                <Link to={completed || role ? postAdLink:"#"}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (!completed && role) {
                        addSnack?.({
                          message: "Please complete your profile first",
                          severity: "error",
                        });
                      }
                      else if(!role){
                        addSnack?.({
                          message: "Please login first",
                          severity: "error",
                        });
                      }
                    }}
                  >
                    Post Ad
                  </Button>
                </Link>
              )}
              <Suspense fallback={null}>
                <MobileMenu postAdLink={postAdLink} />
              </Suspense>
            </>
          ) : (
            <Stack spacing={2} direction="row">
              {fullLinks
                .filter((link) => !link.loggedIn)
                .map((link) => {
                  if (link.Component)
                    return <link.Component key={link.Component.name} />;

                  return (
                    <Button component={Link} to={link.link} key={link.link}>
                      {link.label}
                    </Button>
                  );
                })}

              <Button
                ref={otherLinksButton}
                endIcon={<ExpandMore />}
                onClick={toggleOpenOtherLinks}
                aria-label="Other Links"
                aria-expanded={openOtherLinks}
              >
                Other
              </Button>
              <Menu
                anchorEl={otherLinksButton.current}
                open={openOtherLinks}
                onClose={toggleOpenOtherLinks}
              >
                {otherLinks
                  .filter((link) => !link.loggedIn)
                  .map((link) => {
                    return (
                      <MenuItem
                        disableGutters
                        key={link.link}
                        onClick={toggleOpenOtherLinks}
                      >
                        {!!link.Component ? (
                          <link.Component />
                        ) : (
                          <Button
                            fullWidth
                            component={Link}
                            to={link.link}
                            sx={{
                              paddingInline: (theme) => theme.spacing(2),
                              textAlign: "center",
                            }}
                          >
                            {link.label}
                          </Button>
                        )}
                      </MenuItem>
                    );
                  })}
              </Menu>

              {!adposted && completed && (
                <Button
                  component={Link}
                  to={completed || role ? postAdLink : "#"}
                  variant="contained"
                  onClick={() => {
                    if(!completed && role){
                      addSnack?.({
                        message: "Please complete your profile first",
                        severity: "error",
                      });
                    }
                    else if(!role){
                      addSnack?.({
                        message: "Please login first",
                        severity: "error",
                      });
                    }
                  }}
                >
                  Post Ad
                </Button>
              )}

              <Stack direction="row" spacing={1}>
                {!!token ? (
                  getLinks(role)
                    .filter((link) => link.loggedIn)
                    .map((link) => {
                      const Icon = link.icon ?? Work;
                      if (link.innerLinks)
                        return <InnerLinks link={link} key={link.link} />;
                      return (
                        <IconButton
                          to={link.link}
                          key={link.link}
                          component={Link}
                          onClick={removeDot(link.name)}
                          aria-label={link.label}
                        >
                          <Badge
                            color="error"
                            badgeContent={newDots[link.name ?? "invalid"] ?? 0}
                          >
                            <Tooltip
                              sx={{ zIndex: 99999999 }}
                              title={String(link.label)}
                            >
                              <Icon color="primary" />
                            </Tooltip>
                          </Badge>
                        </IconButton>
                      );
                    })
                ) : (
                  <Link to="/login">
                    <Button variant="outlined">Login</Button>
                  </Link>
                )}
              </Stack>
            </Stack>
          )}
        </nav>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
