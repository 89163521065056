import { Language } from "../../../constants/languages";

export type Data = {
  label: Language;
  id: string;
  content?: Data[];
};
export const DATA_FILTERS: Data[] = [
  {
    label: { en: "Information Technology" },
    id: "information_technology",
    content: [
      {
        id: "application_developer",
        label: { en: "Application Developer" },
      },
      {
        id: "cloud_architect",
        label: { en: "Cloud Architect" },
      },
      {
        id: "cloud_system_engineer",
        label: { en: "Cloud System Engineer" },
      },
      {
        id: "computer_network_architect",
        label: { en: "Computer Network Architect" },
      },
      {
        id: "computer_programmer",
        label: { en: "Computer Programmer" },
      },
      {
        id: "database_administrator",
        label: { en: "Database Administrator" },
      },
      {
        id: "developer",
        label: { en: "Developer" },
      },
      {
        id: "it_manager",
        label: { en: "IT Manager" },
      },
      {
        id: "java_developer",
        label: { en: "Java Developer" },
      },
      {
        id: "net_developer",
        label: { en: ".NET Developer" },
      },
      {
        id: "network_administrator",
        label: { en: "Network Administrator" },
      },
      {
        id: "network_engineer",
        label: { en: "Network Engineer" },
      },
      {
        id: "programmer_analyst",
        label: { en: "Programmer Analyst" },
      },
      {
        id: "software_architect",
        label: { en: "Software Architect" },
      },
      {
        id: "software_engineer",
        label: { en: "Software Engineer" },
      },
      {
        id: "software_quality_assurance_analyst",
        label: { en: "Software Quality Assurance Analyst" },
      },
      {
        id: "support_specialist",
        label: { en: "Support Specialist" },
      },
      {
        id: "system_architect",
        label: { en: "System Architect" },
      },
      {
        id: "system_designer",
        label: { en: "System Designer" },
      },
      {
        id: "systems_administrator",
        label: { en: "Systems Administrator" },
      },
      {
        id: "systems_analyst",
        label: { en: "Systems Analyst" },
      },
      {
        id: "ui_ux_engineer",
        label: { en: "UI/UX Engineer " },
      },
      {
        id: "web_administrator",
        label: { en: "Web Administrator" },
      },
      {
        id: "web_developer",
        label: { en: "Web Developer" },
      },
    ],
  },
  {
    label: { en: "Education" },
    id: "education",
    content: [
      {
        id: "academic_adviser",
        label: { en: "Academic Adviser" },
      },
      {
        id: "administrator",
        label: { en: "Administrator" },
      },
      {
        id: "child_care_assistant",
        label: { en: "Child Care Assistant " },
      },
      {
        id: "dean",
        label: { en: "Dean" },
      },
      {
        id: "instructor",
        label: { en: "Instructor" },
      },
      {
        id: "laboratory_technicians",
        label: { en: "Laboratory Technicians " },
      },
      {
        id: "senior_lecturer",
        label: { en: "Lecturer/Senior Lecturer " },
      },
      {
        id: "librarian",
        label: { en: "Librarian" },
      },
      {
        id: "preschool_teacher",
        label: { en: "Preschool Teacher" },
      },
      {
        id: "principal",
        label: { en: "Principal" },
      },
      {
        id: "professor",
        label: { en: "Professor" },
      },
      {
        id: "program_coordinator",
        label: { en: "Program Coordinator" },
      },
      {
        id: "registrar",
        label: { en: "Registrar" },
      },
      {
        id: "research_assistants",
        label: { en: "Research Assistants" },
      },
      {
        id: "sports_coach",
        label: { en: "Sports Coach" },
      },
      {
        id: "teacher",
        label: { en: "Teacher" },
      },
    ],
  },
  {
    label: { en: "Banking / Insurance" },
    id: "banking_insurance",
    content: [
      {
        id: "audit_manager",
        label: { en: "Audit Manager" },
      },
      {
        id: "bank_examiner",
        label: { en: "Bank Examiner" },
      },
      {
        id: "banking_officer",
        label: { en: "Banking Officer" },
      },
      {
        id: "branch_manager",
        label: { en: "Branch Manager" },
      },
      {
        id: "client_service_manager",
        label: { en: "Client Service Manager" },
      },
      {
        id: "credit_analyst",
        label: { en: "Credit Analyst" },
      },
      {
        id: "customer_service_representative",
        label: { en: "Customer Service Representative" },
      },
      {
        id: "financial_analyst",
        label: { en: "Financial Analyst" },
      },
      {
        id: "front_counter_officers",
        label: { en: "Front Counter Officers" },
      },
      {
        id: "internal_auditor",
        label: { en: "Internal Auditor" },
      },
      {
        id: "investment_analyst",
        label: { en: "Investment Analyst" },
      },
      {
        id: "investment_management_specialist",
        label: { en: "Investment Management Specialist" },
      },
      {
        id: "loan_officer",
        label: { en: "Loan Officer" },
      },
      {
        id: "loss_recovery_manager",
        label: { en: "Loss Recovery Manager" },
      },
      {
        id: "mortgage_consultant",
        label: { en: "Mortgage Consultant" },
      },
      {
        id: "online_customer_service_representative",
        label: { en: "Online Customer Service Representative" },
      },
      {
        id: "teller",
        label: { en: "Teller" },
      },
    ],
  },
  {
    label: { en: "Sales / Marketing" },
    id: "sales_marketing",
    content: [
      {
        id: "account_coordinator",
        label: { en: "Account Coordinator" },
      },
      {
        id: "account_executive",
        label: { en: "Account Executive" },
      },
      {
        id: "assistant_account_executive",
        label: { en: "Assistant Account Executive" },
      },
      {
        id: "brand_marketing_intern",
        label: { en: "Brand Marketing Intern" },
      },
      {
        id: "business_development_officer",
        label: { en: "Business Development Officer" },
      },
      {
        id: "marketing_assistant",
        label: { en: "Marketing Assistant" },
      },
      {
        id: "marketing_manager",
        label: { en: "Marketing Manager" },
      },
      {
        id: "marketing_representative",
        label: { en: "Marketing Representative" },
      },
      {
        id: "regional_account_manager",
        label: { en: "Regional Account Manager" },
      },
      {
        id: "regional_sales_manager",
        label: { en: "Regional Sales Manager" },
      },
      {
        id: "relationship_manager",
        label: { en: "Relationship Manager" },
      },
      {
        id: "sales_assistant",
        label: { en: "Sales Assistant" },
      },
    ],
  },
  {
    label: { en: "Media  / Advertise / Communi." },
    id: "media_advertise",
    content: [
      {
        id: "animator",
        label: { en: "Animator" },
      },
      {
        id: "announcer",
        label: { en: "Announcer" },
      },
      {
        id: "audio_and_video_technician",
        label: { en: "Audio and Video Technician" },
      },
      {
        id: "author",
        label: { en: "Author" },
      },
      {
        id: "camera_operator",
        label: { en: "Camera Operator" },
      },
      {
        id: "content_writer",
        label: { en: "Content Writer" },
      },
      {
        id: "creative_director",
        label: { en: "Creative Director" },
      },
      {
        id: "designer",
        label: { en: "Designer" },
      },
      {
        id: "digital_media_specialist",
        label: { en: "Digital Media Specialist" },
      },
      {
        id: "editor",
        label: { en: "Editor" },
      },
      {
        id: "journalist",
        label: { en: "Journalist" },
      },
      {
        id: "marketing_assistant",
        label: { en: "Marketing Assistant" },
      },
      {
        id: "photographer",
        label: { en: "Photographer" },
      },
      {
        id: "producer",
        label: { en: "Producer" },
      },
      {
        id: "production_artist",
        label: { en: "Production Artist" },
      },
      {
        id: "production_manager",
        label: { en: "Production Manager" },
      },
      {
        id: "project_coordinator",
        label: { en: "Project Coordinator" },
      },
      {
        id: "project_manager",
        label: { en: "Project Manager" },
      },
      {
        id: "proofreader",
        label: { en: "Proofreader" },
      },
      {
        id: "reporter",
        label: { en: "Reporter" },
      },
      {
        id: "sound_engineer",
        label: { en: "Sound Engineer" },
      },
      {
        id: "television_announcer",
        label: { en: "Television Announcer" },
      },
      {
        id: "videographer",
        label: { en: "Videographer" },
      },
    ],
  },
  {
    label: { en: "Apperal / Clothing " },
    id: "apperal_clothing",
    content: [
      {
        id: "fashion_designer",
        label: { en: "Fashion Designer" },
      },
      {
        id: "garment_technologist",
        label: { en: "Garment Technologist" },
      },
      {
        id: "graphic_designer",
        label: { en: "Graphic Designer" },
      },
      {
        id: "head_of_design",
        label: { en: "Head of Design" },
      },
      {
        id: "head_of_marketing",
        label: { en: "Head of Marketing" },
      },
      {
        id: "hr_administrator_assistant",
        label: { en: "HR Administrator / Assistant" },
      },
      {
        id: "pattern_maker_technologist",
        label: { en: "Pattern Maker/Technologist" },
      },
      {
        id: "product_developer",
        label: { en: "Product Developer" },
      },
      {
        id: "production_supervisor",
        label: { en: "Production Supervisor" },
      },
      {
        id: "quality_assurance_manager",
        label: { en: "Quality Assurance Manager" },
      },
      {
        id: "regional_area_manager",
        label: { en: "Regional/Area Manager" },
      },
      {
        id: "sales_manager",
        label: { en: "Sales Manager" },
      },
      {
        id: "shop_manager_assistant",
        label: { en: "Shop Manager Assistant" },
      },
      {
        id: "store_manager",
        label: { en: "Store Manager" },
      },
      {
        id: "stylist",
        label: { en: "Stylist" },
      },
      {
        id: "supervisor",
        label: { en: "Supervisor" },
      },
      {
        id: "visual_merchandiser",
        label: { en: "Visual Merchandiser" },
      },
    ],
  },
  {
    label: { en: "Hospitality / Tourism" },
    id: "hospitality_tourism",
    content: [
      {
        id: "airport_information_officer",
        label: { en: "Airport Information Officer" },
      },
      {
        id: "cabin_crew",
        label: { en: "Cabin Crew" },
      },
      {
        id: "customer_service_agent",
        label: { en: "Customer Service Agent" },
      },
      {
        id: "hotel_manager",
        label: { en: "Hotel Manager" },
      },
      {
        id: "housekeeper",
        label: { en: "Housekeeper" },
      },
      {
        id: "interpreter",
        label: { en: "Interpreter" },
      },
      {
        id: "pilot",
        label: { en: "Pilot" },
      },
      {
        id: "resort_representative",
        label: { en: "Resort Representative" },
      },
      {
        id: "sailing_instructor",
        label: { en: "Sailing Instructor" },
      },
      {
        id: "tour_executive",
        label: { en: "Tour Executive" },
      },
      {
        id: "tour_manager",
        label: { en: "Tour Manager" },
      },
      {
        id: "tourist_guide",
        label: { en: "Tourist Guide" },
      },
      {
        id: "travel_agent",
        label: { en: "Travel Agent" },
      },
    ],
  },
  {
    label: {
      en: "Hotels / Restaurants / Food",
    },
    content: [
      {
        id: "bellman",
        label: { en: "Bellman" },
      },
      {
        id: "catering_sales_manager",
        label: { en: "Catering Sales Manager" },
      },
      {
        id: "chef",
        label: { en: "Chef" },
      },
      {
        id: "cook",
        label: { en: "Cook" },
      },
      {
        id: "driver",
        label: { en: "Driver" },
      },
      {
        id: "events_manager",
        label: { en: "Events Manager" },
      },
      {
        id: "food_and_beverage_manager",
        label: { en: "Food and Beverage Manager" },
      },
      {
        id: "hotel_manager",
        label: { en: "Hotel Manager" },
      },
      {
        id: "housekeeper",
        label: { en: "Housekeeper" },
      },
      {
        id: "maid",
        label: { en: "Maid" },
      },
      {
        id: "meeting_coordinator",
        label: { en: "Meeting Coordinator" },
      },
      {
        id: "quality_controller",
        label: { en: "Quality Controller" },
      },
      {
        id: "receptionist",
        label: { en: "Receptionist" },
      },
      {
        id: "reservationist",
        label: { en: "Reservationist" },
      },
      {
        id: "sales_and_marketing_manager",
        label: { en: "Sales and Marketing Manager" },
      },
      {
        id: "special_events_manager",
        label: { en: "Special Events Manager" },
      },
      {
        id: "supply_chain_specialist",
        label: { en: "Supply Chain Specialist" },
      },
      {
        id: "waiter",
        label: { en: "Waiter/Waitress" },
      },
    ],
    id: "hotels_restaurants_food",
  },
  {
    label: { en: "Medical" },
    id: "medical",
    content: [
      {
        id: "clinical_research_coordinator",
        label: { en: "Clinical Research Coordinator" },
      },
      {
        id: "counselor",
        label: { en: "Counselor" },
      },
      {
        id: "dentist",
        label: { en: "Dentist" },
      },
      {
        id: "dietitian",
        label: { en: "Dietitian" },
      },
      {
        id: "doctor",
        label: { en: "Doctor" },
      },
      {
        id: "language_therapist",
        label: { en: "Language and Speech Therapist" },
      },
      {
        id: "medical_laboratory_technologist",
        label: { en: "Medical Laboratory Technologist" },
      },
      {
        id: "microbiologist",
        label: { en: "Microbiologist" },
      },
      {
        id: "midwife",
        label: { en: "Midwife" },
      },
      {
        id: "nurse",
        label: { en: "Nurse" },
      },
      {
        id: "nutritionist",
        label: { en: "Nutritionist" },
      },
      {
        id: "occupational_therapist",
        label: { en: "Occupational Therapist" },
      },
      {
        id: "optician",
        label: { en: "Optician" },
      },
      {
        id: "pediatrician",
        label: { en: "Pediatrician" },
      },
      {
        id: "pharmacist",
        label: { en: "Pharmacist" },
      },
      {
        id: "physician",
        label: { en: "Physician" },
      },
      {
        id: "physiotherapist",
        label: { en: "Physiotherapist" },
      },
      {
        id: "radiologist",
        label: { en: "Radiologist" },
      },
      {
        id: "surgeon",
        label: { en: "Surgeon" },
      },
      {
        id: "veterinarian",
        label: { en: "Veterinarian" },
      },
    ],
  },
  {
    label: { en: "Fashion / Design / Beauty" },
    id: "fashion_design_beauty",
    content: [
      {
        id: "commercial_photographer",
        label: { en: "Commercial Photographer" },
      },
      {
        id: "creative_director",
        label: { en: "Creative Director" },
      },
      {
        id: "fashion_coordinator",
        label: { en: "Fashion Coordinator" },
      },
      {
        id: "fashion_designer",
        label: { en: "Fashion Designer" },
      },
      {
        id: "graphic_artist",
        label: { en: "Graphic Artist" },
      },
      {
        id: "graphic_designer",
        label: { en: "Graphic Designer" },
      },
      {
        id: "model",
        label: { en: "Model" },
      },
      {
        id: "photographer",
        label: { en: "Photographer" },
      },
      {
        id: "stylist",
        label: { en: "Stylist" },
      },
      {
        id: "technical_designer",
        label: { en: "Technical Designer" },
      },
      {
        id: "textile_fabric_colorist",
        label: { en: "Textile Fabric Colorist" },
      },
    ],
  },
  {
    label: { en: "Agriculture / Environment" },
    id: "agriculture_environment",
    content: [
      {
        id: "agricultural_manager",
        label: { en: "Agricultural Manager" },
      },
      {
        id: "agricultural_specialist",
        label: { en: "Agricultural Specialist" },
      },
      {
        id: "agronomist",
        label: { en: "Agronomist" },
      },
      {
        id: "conservation_planner",
        label: { en: "Conservation Planner" },
      },
      {
        id: "conservationist",
        label: { en: "Conservationist" },
      },
      {
        id: "ecologist",
        label: { en: "Ecologist" },
      },
      {
        id: "horticulturalist",
        label: { en: "Horticulturalist" },
      },
      {
        id: "landscaper",
        label: { en: "Landscaper" },
      },
      {
        id: "municipal_forester",
        label: { en: "Municipal Forester" },
      },
      {
        id: "plant_biologist",
        label: { en: "Plant Biologist" },
      },
      {
        id: "plant_ecologist",
        label: { en: "Plant Ecologist" },
      },
      {
        id: "soil_and_plant_scientist",
        label: { en: "Soil and Plant Scientist" },
      },
      {
        id: "soil_engineer",
        label: { en: "Soil Engineer" },
      },
      {
        id: "water_conservationist",
        label: { en: "Water Conservationist" },
      },
      {
        id: "water_management_planner",
        label: { en: "Water Management Planner" },
      },
      {
        id: "water_quality_specialist",
        label: { en: "Water Quality Specialist" },
      },
      {
        id: "wetlands_biologist",
        label: { en: "Wetlands Biologist" },
      },
      {
        id: "wetlands_designer",
        label: { en: "Wetlands Designer" },
      },
      {
        id: "wildlife_administrator",
        label: { en: "Wildlife Administrator" },
      },
      {
        id: "wildlife_consultant",
        label: { en: "Wildlife Consultant" },
      },
      {
        id: "wildlife_forensics",
        label: { en: "Wildlife Forensics" },
      },
      {
        id: "wildlife_inspector",
        label: { en: "Wildlife Inspector" },
      },
      {
        id: "wildlife_manager",
        label: { en: "Wildlife Manager" },
      },
      {
        id: "wildlife_officer",
        label: { en: "Wildlife Officer" },
      },
    ],
  },
  { label: { en: "Other" }, id: "other" },
];

const genSelectables = (data: Data) => ({
  id: data.id,
  label: data.label.en,
});
export const DATA_INDUSTRY = DATA_FILTERS.map(genSelectables);

export const getJobTitle = (
  industry: Data["id"],
  jobTitle?: Data["id"],
  code?: keyof Language
) => {
  return DATA_FILTERS.find((ind) => ind.id === industry)?.content?.find(
    (job) => job.id === jobTitle
  )?.label?.[code ?? "en"];
};
