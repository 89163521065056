import {
  DATA_ALIVE_STATUS,
  DATA_MARITAL_STATUS,
} from "../../../constants/input-data";
import {
  Credentials,
  ErrorHelps,
  ExtendedInputField,
  InputField,
} from "../../../utils/auth-types";
import selectInput from "../../select-input";

export const getInputs = (credentials: Credentials) => {
  const isMarried = !!(
    DATA_MARITAL_STATUS.find(
      // @ts-ignore
      (status) => status.id === credentials.marital_status
    )?.label === "Married"
  );

  const inputs: ExtendedInputField[] = [
    {
      name: "father_name",
      props: { label: "Father's Name" },
      validator: () => true,
    },
    {
      name: "father_occupation",
      props: { label: "Father's Occupation" },
      validator: () => true,
    },
    {
      name: "father_is_alive",
      CustomComponent: selectInput(
        DATA_ALIVE_STATUS,
        credentials.father_is_alive
      ),
      column: 12,
      props: { label: "Father Alive / Deceased", sx: { maxWidth: 380 } },
      validator: () => true,
    },
    {
      name: "mother_name",
      props: { label: "Mother’s Name"},
      validator: () => true,
    },
    {
      name: "mother_occupation",
      props: { label: "Mother’s Occupation" },
      validator: () => true,
    },
    {
      name: "mother_is_alive",
      CustomComponent: selectInput(
        DATA_ALIVE_STATUS,
        credentials.mother_is_alive
      ),
      validator: () => true,
      props: {
        label: "Mother Alive / Deceased",
        sx: { maxWidth: 380 },
      },
      column: 12,
    },
    {
      name: "marital_status",
      CustomComponent: selectInput(
        DATA_MARITAL_STATUS,
        credentials.marital_status
      ),
      column: 12,
      props: { label: "Marital Status", required: true },
      validator: (id) =>
        !!DATA_MARITAL_STATUS.find((status) => status.id === id),
    },
    {
      name: "spouse_name",
      props: { label: "Spouse's Name", disabled: !isMarried },
      validator: () => true,
    },
    {
      name: "spouse_occupation",
      validator: () => true,
      props: {
        label: "Spouse's Occupation",
        disabled: !isMarried,
      },
    },
    {
      name: "number_of_children",
      dependancies: ["marital_status"],
     
      props: { label: "Number of Children", type: "number", defaultValue: 0 ,disabled: !isMarried},
      validator: () => true,
    },
  ];
  return inputs;
};

export const booleans: InputField[] = [
  {
    name: "make_visible",
    props: { label: "Family information make visible to the public" },
  },
];

export const errorHelps: ErrorHelps = {};
