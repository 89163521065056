import {
  Email,
  Facebook,
  Instagram,
  LinkedIn,
  LocalPhone,
} from "@mui/icons-material";
import {
  Box,
  Container,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, FC, useState } from "react";
import Social from "../../components/social-link";
import Header from "../../components/static-pages/header";
import SubmitButton from "../../components/submit-button";
import { FULL_NAME_REGEX, MOBILE_NUM_REGEX } from "../../constants/regex";
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  LINKEDIN_LINK,
} from "../../constants/social-links";
import { useErrors } from "../../hooks/error.hook";
import * as chatService from "../../services/chat-service";
import { useLanguage } from "../../store/providers/lang.provider";
import { useSnackbar } from "../../store/providers/snackbar.provider";
import { Credentials, ErrorHelps, InputField } from "../../utils/auth-types";

interface ContactPageProps {}

const ContactPage: FC<ContactPageProps> = () => {
  const [details, setDetails] = useState<Credentials>({});
  const { code } = useLanguage();
  const { addSnack } = useSnackbar();

  const { checkErrors, updateError, hasErrors, addError } = useErrors(inputs);

  const updateCredentials =
    (name: string, validate: InputField["validator"]) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      updateError(name, validate?.(value));
      setDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
    };

  const submit = async () => {
    try {
      checkErrors();
      const res = await chatService.contact(details);
      if (res?.success) {
        addSnack?.({
          severity: "success",
          message: res.result,
        });
        setDetails({}); // clear current state;
      }
    } catch (error: any) {
      addError?.(error.message);
    }
  };
  return (
    <>
      <Header title={{ en: "Contact Us" }} />
      <Container style={{ marginBottom: 100 }}>
        <Stack
          direction={{ md: "row", xs: "column" }}
          justifyContent="space-around"
          gap={10}
        >
          <Box flex={1}>
            <Typography variant="h2" color="primary">
              Leave a Massege
            </Typography>
            <Stack spacing={2} paddingY={2}>
              {inputs.map((input) => {
                const hasError = hasErrors(input.name);
                const Input = input.CustomComponent ?? TextField;
                return (
                  <Input
                    fullWidth
                    {...input.props}
                    error={hasError}
                    name={input.name}
                    key={input.name}
                    value={details?.[input.name] ?? ""}
                    helperText={hasError ? errorHelps[input.name]?.[code] : ""}
                    onChange={updateCredentials(input.name, input.validator)}
                  />
                );
              })}
              <SubmitButton onClick={submit} fullWidth={false}>
                Submit
              </SubmitButton>
            </Stack>
          </Box>
          <Box flex={1}>
            <Typography variant="h2" color="primary">
              Contact Details
            </Typography>

            <List>
              <ListItem>
                <ListItemIcon>
                  <LocalPhone color="primary" />
                </ListItemIcon>
                <ListItemText>
                  <Stack
                    divider={
                      <Divider
                        className="divider"
                        orientation="vertical"
                        flexItem
                      />
                    }
                    direction="row"
                    spacing={2}
                  >
                    <Typography color="secondary">011 233 4 555</Typography>
                    <Typography color="secondary">011 222 3 444</Typography>
                  </Stack>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Email color="primary" />
                </ListItemIcon>
                <ListItemText>
                  <Typography color="secondary">jobhear@gmail.com</Typography>
                </ListItemText>
              </ListItem>
            </List>

            <Typography variant="h2" color="primary">
              Follow Us
            </Typography>
            <Stack direction="row" spacing={2}>
              <Social icon={Facebook} link={FACEBOOK_LINK} />
              <Social icon={Instagram} link={INSTAGRAM_LINK} />
              <Social icon={LinkedIn} link={LINKEDIN_LINK} />
            </Stack>
          </Box>
        </Stack>
      </Container>
    </>
  );
};

const inputs: InputField[] = [
  {
    name: "full_name",
    validator: (name) => FULL_NAME_REGEX.test(name),
    props: {
      placeholder: "Enter your Full Name",
      label: "Full Name",
      required: true,
    },
  },
  {
    name: "contact_number",
    validator: (number) => MOBILE_NUM_REGEX.test(number),
    props: {
      placeholder: "Enter your mobile number",
      label: "Mobile Number",
      required: true,
    },
  },
  {
    name: "message",
    validator: (message) => /.*/m.test(message),
    props: {
      multiline: true,
      placeholder: "Write your message",
      label: "Message",
      rows: 10,
      required: true,
    },
  },
];

const errorHelps: ErrorHelps = {
  full_name: {
    en: "Only Letters are allowed",
    si: "",
    tm: "",
  },
  contact_number: {
    en: "Entered Number is Invalid",
  },
  message: {
    en: "need to be filled",
  },
};
export default ContactPage;
